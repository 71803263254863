import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ShinyButtonInvertedButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(({ wrapperProps, linkProps, elementType, label, onFocus, onBlur }, ref) => {
  return (
    <div {...wrapperProps} className={skinsStyle.ShinyButtonInverted}>
      <SiteButtonContent
        linkProps={linkProps}
        elementType={elementType}
        className={skinsStyle.link}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <div className={skinsStyle.labelwrapper}>
          <span className={skinsStyle.label}>{label}</span>
        </div>
      </SiteButtonContent>
    </div>
  );
});

const ShinyButtonInvertedButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ShinyButtonInvertedButtonSkin} ref={ref} />
);

export default ShinyButtonInvertedButton;
